export const scrollTo = (offset: number): void => {
  window.scrollTo({
    top: offset,
    behavior: 'smooth',
  });
};

export const scrollToTop = (): void => {
  scrollTo(0);
};

export default scrollTo;
