import classNames from 'classnames';
import navigation from '~/translations/data/navigation';
import scrollTo from '~/utils/scroll.util';

const Menu = ({
  className,
  isTranparent,
  onHashChange,
}: {
  className?: string;
  isTranparent: boolean;
  onHashChange?: CallableFunction;
}) => {
  const scrollToElementIfHashIsPresent = (e) => {
    if (e.target.hash) {
      e.preventDefault();
      scrollTo(
        document.getElementById(e.target.hash.substring(1)).offsetTop - 110,
      );
      onHashChange?.();
    }
  };

  return (
    <div className="sticky top-0">
      <nav className={className}>
        <ul
          className={classNames(
            'flex flex-col items-center justify-between m-auto font-bold  uppercase lg:flex-row font-roboto',
            {
              'text-white': isTranparent,
            },
          )}
        >
          {navigation.map((item, index) => (
            <li
              className={classNames('my-4 cursor-pointer lg:my-0', {
                'md:mr-6': index !== navigation.length - 1,
              })}
            >
              <a
                className={classNames(
                  `flex items-center justify-between transition-all duration-300 no-underline`,
                  {
                    'border border-white p-2 uppercase hover:bg-white md:hover:text-green-700':
                      item.highlighted,
                    'border-main': isTranparent === false,
                  },
                )}
                onClick={(e) => scrollToElementIfHashIsPresent(e)}
                href={item.link}
                target={item.shouldOpenNewWindow ? '_blank' : null}
                rel="noreferrer"
              >
                {item.login_icon && <span className="mr-2 icon-user" />}
                {item.title}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Menu;
