const firms: {
  title: string;
  logo: string;
  logo_white: string;
  address: string;
  city: string;
  mail: string;
  tel: string;
  facebook: string;
  linkedin: string;
  vimeo: string;
  btw?: string;
  rpr?: string;
}[] = [
  {
    title: 'INNI group',
    logo: '/images/logo-innigroup-blue@2x.png',
    logo_white: '/images/logo-innigroup-white@2x.png',
    address: 'Industrielaan 5',
    city: '8501 Heule',
    mail: 'publishers@innigroup.com',
    tel: '+32 (0) 056 36 32 09',
    facebook: 'https://www.facebook.com/INNIpublishers',
    linkedin: 'https://be.linkedin.com/company/innipublishers',
    vimeo: '',
    btw: 'BE 0418420485',
  },
  {
    title: 'Wiels',
    logo: '/images/logo-wiels@2x.png',
    logo_white: '/images/logo-wiels-white.png',
    address: 'Deerlijkstraat 58A',
    city: '8550 Zwevegem',
    mail: 'info@wiels.be',
    tel: '+32 (0) 056 75 42 81',
    facebook: '',
    linkedin: 'https://be.linkedin.com/company/wiels-zwevegem',
    vimeo: '',
  },
];

export default firms;
