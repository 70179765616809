import { XIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import Menu from './menu.component';

const MobileNav = ({
  onClose,
  menu,
}: {
  onClose: CallableFunction;
  menu: boolean;
}) => {
  return (
    <div
      className={classNames(
        'fixed top-0 left-0 z-50 w-screen overflow-hidden bg-green-700 transition-all duration-300 lg:hidden',
        {
          'h-full': menu,
          'h-0': !menu,
        },
      )}
    >
      <div className="container flex justify-end pt-4 md:pt-8">
        <XIcon
          onClick={() => onClose()}
          className={classNames(
            'z-50 w-10 h-10 cursor-pointer lg:hidden text-white',
          )}
        />
      </div>
      <div className="flex justify-center items-center h-full w-full absolute top-0">
        <Menu isTranparent onHashChange={onClose} />
      </div>
    </div>
  );
};

export default MobileNav;
