const advantages: { title: string; content: string; image: string }[] = [
  {
    title: 'Wettelijk register',
    content: 'Altijd toegang tot het ISO-conforme wettelijk register.',
    image: '/images/register.svg',
  },
  {
    title: 'Actiebeheer',
    content: 'Eenvoudig opvolgen en toewijzen van taken.',
    image: '/images/action-management.svg',
  },
  {
    title: 'Audits',
    content: 'Uitvoeren conformiteitsaudits + opvolging & monitoring.',
    image: '/images/audits.svg',
  },
  {
    title: 'Status wetgeving',
    content: 'Handig overzicht van de laatste versie van de wetgeving.',
    image: '/images/status-law.svg',
  },
  {
    title: 'Check op diverse niveaus',
    content: 'Dit op zowel wet-, decreet-, hoofd- als artikelniveau.',
    image: '/images/checks.svg',
  },
  {
    title: 'Meten performantie',
    content: 'De mate waarin je in orde bent conform de wetgeving.',
    image: '/images/performance.svg',
  },
];

export default advantages;
