/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { MenuIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import Image from 'next/image';

import classNames from 'classnames';
import { Page } from '~/types';
import Menu from '~/components/home/menu.component';
import MobileNav from '~/components/home/mobile-nav.component';
import firms from '~/translations/data/firms';
import advantages from '~/translations/data/advantages';
import mailtoUtil from '~/utils/mailto.util';
import {
  ADMINISTATIVE_CONTACT_EMAIL,
  DEMO_MAIL_BODY,
  DEMO_MAIL_SUBJECT,
} from '~/constants';
import { scrollToTop } from '~/utils/scroll.util';

const Home: Page = () => {
  const [isHeaderTransparent, setIsHeaderTransparent] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const scrollHandler = () => {
      const scrollContainer = document.getElementsByTagName('html')[0];

      if (scrollContainer) {
        if (scrollContainer.scrollTop > 0 && isHeaderTransparent === true) {
          setIsHeaderTransparent(false);
        }
        if (scrollContainer.scrollTop === 0 && isHeaderTransparent === false) {
          setIsHeaderTransparent(true);
        }
      }
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [isHeaderTransparent, setIsHeaderTransparent]);

  return (
    <>
      <div
        className={classNames(
          'fixed transition-colors top-0 z-20  w-full py-4 transform -translate-x-1/2 left-1/2 md:py-8',
          {
            'bg-white shadow': isHeaderTransparent === false,
          },
        )}
      >
        <div className="container flex items-center justify-between">
          <div className="cursor-pointer" onClick={scrollToTop}>
            <h1 className="hidden">INNIsure</h1>
            <img
              style={{ width: 201 }}
              src={
                isHeaderTransparent
                  ? '/images/logo.png'
                  : '/images/header-logo-dark.png'
              }
              alt="INNIsure logo"
            />
          </div>
          <div>
            <Menu
              className="hidden lg:block"
              isTranparent={isHeaderTransparent}
            />
          </div>
          <MenuIcon
            onClick={() => setIsMenuOpen(true)}
            className={classNames('w-10 h-10 cursor-pointer lg:hidden', {
              'text-white': isHeaderTransparent,
            })}
          />
        </div>
      </div>
      <MobileNav onClose={() => setIsMenuOpen(false)} menu={isMenuOpen} />
      <div className="overflow-x-hidden">
        <div className="container">
          <article className="relative">
            <div className="absolute top-0 z-0 w-screen h-full transform -translate-x-1/2 bg-center bg-no-repeat bg-cover bg-header left-1/2" />

            <div
              className="relative grid grid-cols-1 pb-12 pt-52 md:grid-cols-12 gap:8 lg:py-56"
              id="home"
            >
              <div className="text-white md:col-end-13 lg:col-end-13 md:col-span-7 lg:col-span-4">
                <p className="mb-4 font-bold text-24 md:text-40 font-helvetica">
                  Ga voor een praktischer en efficiënter welzijns- en
                  milieubeleid!
                </p>
                <p className="mb-10 font-bold font-roboto">
                  De organisatietool voor elke preventieadviseur &amp;
                  milieucoördinator
                </p>
                <a
                  href={mailtoUtil({
                    recipient: ADMINISTATIVE_CONTACT_EMAIL,
                    subject: DEMO_MAIL_SUBJECT,
                    body: DEMO_MAIL_BODY,
                  })}
                  className="px-12 py-4 no-underline uppercase transition-all duration-300 bg-green-300 cursor-pointer font-helvetica text-14 hover:bg-green-700"
                >
                  demo aanvragen
                </a>
              </div>
            </div>
          </article>

          <article
            className="grid grid-cols-1 py-12 lg:grid-cols-12 lg:gap-8"
            id="over"
          >
            <div className="relative leading-loose md:col-span-5">
              <section className="mb-8">
                <h3 className="mb-4 text-green-700 xl:mb-8 text-40 font-helvetica">
                  Wat is INNIsure?
                </h3>
                <p className="mb-4 text-gray-400 font-roboto">
                  <strong>INNIsure</strong> is een multifunctionele{' '}
                  <strong>online legal compliance tool</strong> die de
                  thema&apos;s <strong>milieu en welzijn</strong> binnen elke
                  organisatie centraal zet.
                </p>
                <p className="mb-4 text-gray-400 font-roboto">
                  Het online platform is er om{' '}
                  <strong>elke organisatie draaiende te houden</strong>. Bekijk
                  ons gerust als een <strong>complete organisatietool</strong>{' '}
                  voor elke{' '}
                  <strong>milieucoördinator en preventieadviseur</strong> om een
                  beter &amp; efficiënter{' '}
                  <strong>welzijns- en milieubeleid</strong> uit te werken.
                </p>
              </section>
              <section className="mb-8">
                <h3 className="mb-4 leading-snug text-28 font-helvetica">
                  Wat heeft INNIsure te bieden?
                </h3>
                <p className="mb-4 text-gray-400 font-roboto">
                  Met <strong>INNIsure</strong> heb je op elk moment van de dag{' '}
                  <strong>toegang tot het wettelijk register</strong> en krijg
                  je een handig overzicht van de{' '}
                  <strong>up to date status van de wetgeving</strong>.
                </p>
                <p className="mb-4 text-gray-400 font-roboto">
                  Daarnaast laat <strong>INNIsure</strong> je toe om heel
                  eenvoudig audits voor te bereiden en er een{' '}
                  <strong>gepast actiebeheer</strong> aan te koppelen.
                </p>
              </section>
            </div>

            <div className="lg:col-span-5 lg:col-end-13">
              <div className="relative w-full lg:w-96 xl:w-4/5 about_container">
                <img
                  src="/images/about.png"
                  alt="what"
                  className="about_image"
                />
              </div>
            </div>
          </article>
          <article className="relative py-4 lg:py-10" id="voordelen">
            <div className="absolute top-0 w-screen h-full transform -translate-x-1/2 bg-gray-200 left-1/2" />
            <div className="relative">
              <h2 className="mb-8 text-green-700 xl:mb-16 text-helvetica text-40">
                Voordelen
              </h2>
              <div className="items-end md:grid md:grid-cols-2 md:gap-4 xl:grid-cols-3">
                {advantages.map((advantage) => (
                  <>
                    <section className="flex flex-col items-center mb-4 text-gray-400 md:block margin-auto font-roboto">
                      <div className="">
                        <img src={advantage.image} alt={advantage.title} />
                      </div>
                      <h3 className=" text-17">{advantage.title}</h3>
                      <p className="text-14">{advantage.content}</p>
                    </section>
                  </>
                ))}
              </div>
            </div>
          </article>
          {/* @todo: unhide once copy is available */}
          {/* <article className="py-4 lg:py-10" id="klanten">
            <h2 className="mb-8 leading-normal text-green-700 xl:mb-16 text-40 font-helvetica">
              Wat zeggen onze gebruikers?
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-12 md:gap-8">
              {users.map((user, index) => (
                <>
                  <section
                    className={`relative mb-4 md:col-span-10 bg-green-200  my-4 font-roboto ${
                      index % 2 !== 0 && 'md:col-start-3'
                    } `}
                  >
                    <div className="absolute top-0 pl-4 transform -translate-y-1/2 md:pl-20">
                      <div className="relative w-1/2">
                        <Image
                          src={user.logo.src}
                          objectFit="contain"
                          width={user.logo.width}
                          height={user.logo.height}
                        />
                      </div>
                    </div>
                    <div className="relative px-4 py-8 overflow-hidden md:px-20 md:py-8">
                      <div className="absolute right-0 z-10 transform rotate-45 translate-x-40 bg-gray-100 -translate-y-52 w-80 w-104 h-52" />
                      <div />
                      <div className="relative">
                        <h3 className="mb-4 text-green-400 text-18">
                          {user.quote}
                        </h3>
                        <p className="text-gray-300 text-14">{user.name}</p>
                      </div>
                    </div>
                  </section>
                </>
              ))}
            </div>
          </article> */}
          <article className="relative py-20" id="demo">
            <div className="absolute top-0 w-screen h-full transform -translate-x-1/2 left-1/2 bg-demo_gradient" />
            <div className="relative grid grid-cols-1 md:grid-cols-12 gap:8">
              <div className="flex items-center justify-center md:col-span-3">
                <div className="mb-8 overflow-hidden rounded-full md:mb-0">
                  <Image src="/images/demo.jpg" width="284" height="284" />
                </div>
                <div className="absolute top-0 w-1/2 h-1/2 md:w-full md:h-full">
                  <Image
                    src="/images/demo_border.svg"
                    layout="fill"
                    objectFit="contain"
                  />
                </div>
              </div>
              <div className="flex flex-col items-start justify-center mr-4 text-white md:col-start-5 md:col-span-7">
                <h2 className="mb-4 font-bold text-white font-helvetica text-40">
                  Vraag een demo aan
                </h2>
                <p className="mb-4 font-bold font-roboto">
                  Benieuwd naar wat INNIsure jou kan bieden?
                </p>
                <p className="font-roboto">
                  De collega’s van <strong>INNI GROUP</strong> plannen via een{' '}
                  <strong>one on one</strong> demo graag een{' '}
                  <strong>eerste kennismaking</strong> in. Zo krijg je meteen
                  meer info en uitleg over onze tool. Aarzel dus niet om je{' '}
                  <strong>demo hier aan te vragen.</strong>
                </p>
                <a
                  href={mailtoUtil({
                    recipient: ADMINISTATIVE_CONTACT_EMAIL,
                    subject: DEMO_MAIL_SUBJECT,
                    body: DEMO_MAIL_BODY,
                  })}
                  className="z-10 flex-shrink px-12 py-4 my-4 no-underline uppercase transition-all duration-300 bg-green-700 cursor-pointer hover:bg-green-300"
                >
                  demo aanvragen
                </a>
              </div>
            </div>
          </article>
          <div className="py-12 " id="contact">
            <section className="grid grid-cols-1 md:grid-cols-12">
              <div className=" md:col-span-10 lg:col-span-7">
                <h2 className="mb-8 leading-normal text-green-700 lg:w-2/3 text-40">
                  INNIsure, made by
                  <br />
                  INNI Publishers &amp; Wiels
                </h2>
                <div>
                  <p className="mb-4 text-gray-400 font-roboto">
                    INNIsure is ontstaan dankzij een initiatief tussen{' '}
                    <strong>INNI publishers</strong> en <strong>Wiels</strong>.
                    Beide organisaties, gespecialiseerd in milieu en welzijn,
                    zochten naar een praktische en efficiënte manier om de{' '}
                    <strong>
                      preventieadviseurs &amp; milieucoördinatoren
                    </strong>{' '}
                    binnen elke organisatie een handje toe te steken.
                  </p>
                  <p className="mb-4 text-gray-400 font-roboto">
                    Elkaar daarin vinden gebeurde heel snel. Dankzij de inhoud,
                    content en expertise die zowel INNI publishers als Wiels kan
                    voorleggen, ontstond de{' '}
                    <strong>organisatietool INNIsure</strong>.
                  </p>
                </div>
              </div>
            </section>
            <section className="py-4">
              <h2 className="mb-8 font-helvetica text-30">Contacteer Ons</h2>
              <div className="flex flex-col justify-between lg:items-center lg:flex-row">
                {firms.map((firm) => (
                  <>
                    <div className="grid grid-cols-4 gap-8 py-4 md:col-span-5 md:grid-cols-12">
                      <div className="relative w-20 h-20 md:h-auto md:w-40 md:col-span-4">
                        <Image
                          src={firm.logo}
                          height={55}
                          width={160}
                          objectFit="contain"
                        />
                      </div>
                      <div className="col-span-3 md:col-span-8 font-roboto">
                        <address className="mb-2 not-italic">
                          {firm.address}, {firm.city}
                        </address>
                        <div className="flex flex-col justify-start text-green-400 md:items-center md:flex-row">
                          <a href={mailtoUtil({ recipient: firm.mail })}>
                            {firm.mail}
                          </a>
                          <div className="hidden md:block h-4 bg-green-400 w-0.5 mx-2" />
                          <a href={`tel:${firm.tel}`}>{firm.tel}</a>
                        </div>
                        <div className="flex items-center text-green-400">
                          {firm.facebook && (
                            <a
                              target="_blank"
                              href={firm.facebook}
                              className="py-4 mr-4 text-xl no-underline transform hover:scale-105"
                              rel="noreferrer"
                            >
                              <span className="icon-facebook" />
                            </a>
                          )}
                          {firm.linkedin && (
                            <a
                              target="_blank"
                              href={firm.linkedin}
                              className="py-4 mr-4 text-xl no-underline transform hover:scale-105"
                              rel="noreferrer"
                            >
                              <span className="icon-linkedin" />
                            </a>
                          )}
                          {firm.vimeo && (
                            <a
                              target="_blank"
                              href={firm.vimeo}
                              className="py-4 mr-4 text-xl no-underline transform hover:scale-105"
                              rel="noreferrer"
                            >
                              <span className="icon-vimeo" />
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </section>
          </div>
          <footer className="relative">
            <div className="absolute top-0 w-screen h-full transform -translate-x-1/2 bg-green-700 left-1/2" />
            <div className="relative grid grid-cols-1 leading-normal lg:grid-cols-2 lg:gap-8">
              {firms.map((firm) => (
                <>
                  <div className="relative gap-8 py-4 text-green-600 text-17 md:text-12 md:flex">
                    <div className="relative w-40 h-40 md:w-20 md:h-auto">
                      <Image
                        src={firm.logo_white}
                        objectFit="contain"
                        layout="fill"
                      />
                    </div>
                    <div>
                      <p className="py-2 font-bold">{firm.title}</p>
                      <address className="not-italic">
                        <p className="py-2">{firm.address}</p>
                        <p className="py-2">{firm.city}</p>
                      </address>
                    </div>
                    <div className="flex flex-col">
                      <a className="py-2" href={`tel:${firm.tel}`}>
                        {firm.tel}
                      </a>
                      <a
                        className="py-2"
                        href={mailtoUtil({ recipient: firm.mail })}
                      >
                        {firm.mail}
                      </a>
                      <div className="flex items-center py-2">
                        {firm.facebook && (
                          <a
                            href={firm.facebook}
                            className="mr-4 text-base no-underline transform hover:scale-105"
                          >
                            <span className="icon-facebook" />
                          </a>
                        )}
                        {firm.linkedin && (
                          <a
                            href={firm.linkedin}
                            className="mr-4 text-base no-underline transform hover:scale-105"
                          >
                            <span className="icon-linkedin" />
                          </a>
                        )}
                        {firm.vimeo && (
                          <a
                            href={firm.vimeo}
                            className="mr-4 text-base no-underline transform hover:scale-105"
                          >
                            <span className="icon-vimeo" />
                          </a>
                        )}
                      </div>
                    </div>
                    <div>
                      {firm.btw && <p className="py-2">BTW {firm.btw}</p>}
                      {firm.rpr && <p className="py-2">RPR {firm.rpr}</p>}
                    </div>
                  </div>
                </>
              ))}
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

Home.isPublic = true;

export default Home;
