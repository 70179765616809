export default ({
  recipient,
  subject,
  body,
}: {
  recipient: string;
  subject?: string;
  body?: string;
}): string => {
  const queryValues = {
    subject,
    body,
  };
  const queryString = Object.keys(queryValues).reduce((result, key) => {
    if (queryValues[key]) {
      return `${result}&${key}=${queryValues[key]}`;
    }

    return result;
  }, '');

  return encodeURI(`mailto:${recipient}?${queryString}`);
};
